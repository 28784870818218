<template>
  <router-view></router-view>
</template>

<script>
import { Multiselect } from "vue-multiselect";
import Vue from "vue";
import VueSimpleSuggest from "vue-simple-suggest";

Vue.component("Multiselect", Multiselect);
Vue.component("VueSimpleSuggest", VueSimpleSuggest);

export default {
  components: {
    //
  }
};
</script>

<style></style>
